<template>
  <a-modal
    title="新建购销合同附件"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="附件">
              <a-upload
                v-decorator="['url', {valuePropName: 'url', rules: [{required: true, message: '必填项，请填写信息'}]}]"
                name="file"
                accept=".pdf,.jpg,.jpeg,.png"
                :action="actionUrl"
                :data="new_multipart_params"
                list-type="picture-card"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { oss_token_list } from '@/api/oss_token'

// 表单字段
const fields = ['id', 'name', 'code']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      actionUrl: '',
      fileList: [],
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    console.log('==================', this.model)
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.fileList = []
    oss_token_list().then(({ data }) => {
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
  },
  methods: {
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_multipart_params.key = 'trade_files_' + file.uid + '.' + suffix
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      // if (!isJpgOrPng) {
      //   this.$message.error('You can only upload JPG file!')
      // }
      const isLt500M = file.size / 1024 / 1024 < 500
      return isLt500M
    },
    handlePreview (file) {
      window.open(file.url, '_blank')
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      // console.log('我是图片', fileList)
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.fileList.map((element, index, array) => {
          console.log(index + '-' + element + array)
          if (element.uid === file.uid) {
            this.fileList[index].url = this.actionUrl + '/' + this.new_multipart_params.key
          }
        })
        console.log('我是图片', this.fileList)
        // console.log(this.actionUrl + '/' + this.new_multipart_params.key)
      }
    }
  }
}
</script>
